"use client";
import React, {useCallback, useEffect, useMemo, useRef} from "react";
import PublicIcons from "@/components/basic/PublicIcon";
import useClientContext from "@/context/ClientContext";
import cn from "@/libs/cn";
import { useDataPublicDependencies } from "@/modules/public/hooks/useData";
import ImageFlag from "@/components/ImageFlag";
import useClientRouter from "@/hooks/useClientRouter";
import Select from "@/controls/Select";

import styles from "./styles.module.scss";
import authRequests from '@/modules/auth/requests';
import {useDataPreferences} from '@/modules/profile/hooks/useData';

interface SelectLocaleProps {}

const SelectLocale = (props: SelectLocaleProps) => {
  const { locale, languages, translate, isAuthorized } = useClientContext();
  const router = useClientRouter();
  const { replace } = useClientRouter();
  const dependencies = useDataPublicDependencies();

  let countryCode = dependencies.data.find((i) => i.language === locale)?.country;

  // TODO hard code for English
  if (`${locale}`.toLowerCase() === "en") {
    countryCode = "UK";
  }

  const options = useMemo(() => languages.data.map((i) => ({ value: i.code, label: i.name })), [languages.data]);

  const lang = useMemo(
    () => languages.data.find((i) => `${i.code}`.toLowerCase() === `${locale}`.toLowerCase()),
    [languages.data, locale]
  );

  // TODO: return when preferences returned on back
  // const preferences = useDataPreferences();
  const onChange = useCallback((code: string) => {
    // TODO fast fix, find better solution (asPath from router contains previous value)
    const asPath = `${global.location?.pathname}${global.location?.search}`;
    replace(asPath, { locale: code });
    // if(isAuthorized) {
    //   authRequests
    //     .updatePreferences({ ...preferences.data, language: code })
    //     .then(() => preferences.mutate());
    // }
  }, [replace]);

  return (
    <Select className={styles.SelectLocale} options={options} onChange={onChange}>
      <>
        {countryCode && <ImageFlag code={countryCode} />}
        <div className={styles.value}>{lang?.name || translate("Loading")}</div>
        <PublicIcons name="arrowCaretDown" height={18} width={18} data-arrow="icon-arrow" />
      </>
    </Select>
  );
};

export default SelectLocale;
