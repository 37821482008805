"use client";
import cn from "@/libs/cn";
import Avatar from "@/components/Avatar";
import Link from "@/components/basic/Link";
import useClientContext from "@/context/ClientContext";
import routesProfile from "@/modules/profile/constants/routesProfile";
import { useDataProfile } from "@/modules/profile/hooks/useData";
import useClientRouter from '@/hooks/useClientRouter';

import styles from "./styles.module.scss";

const ButtonAuth = () => {
  const {
    translate,
    isAuthorized,
    isSeller,
    modules: { auth },
  } = useClientContext();

  const { pathname, asPath } = useClientRouter();

  const { data: dataProfile } = useDataProfile();

  return (
    <span
      onClick={isAuthorized ? () => {} : () => {
        // TODO: quick fix, find better solution
        setTimeout(() => auth.actions.openLogIn(), 1000);
      }}
    >
      <Link
        href={isAuthorized ? routesProfile.profile() : "#"}
        // target={isSeller ? "_blank" : undefined}
        className={cn(styles.anchor, styles.wrapAvatar)}
        disabled={!isAuthorized}
      >
        <>
          <Avatar width={20} height={20} src={dataProfile?.avatar} />
          {isAuthorized ? (
            <div className={styles.name}>{isSeller ? translate("Seller") : translate("Profile")}</div>
          ) : (
            <div className={styles.name}>{translate("Sign In")}</div>
          )}
        </>
      </Link>
    </span>
  );
};

export default ButtonAuth;
